// Here you can add other styles
.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem 1rem;
    border-bottom: 1px solid #dee2e6;
    background-color: #f8f9fa;
  }
  
  .modal-title {
    margin: 0;
    font-size: 1.25rem;
  }
  
  .modal-close {
    border: none;
    background: transparent;
    font-size: 1.5rem;
    line-height: 1;
    color: #000;
    opacity: 0.5;
    cursor: pointer;
  }
  
  .file-tree-selector > .c-row > div {
    height: 100%;
}

.extended-row > div {
  height: 100% !important;
}

.react-checkbox-tree {
  overflow-x:auto;
}
